<template>
    <div style="margin-top: 2px" v-if="contentObj">
        <el-tooltip :content="contentObj.tooltip" v-if="contentObj.value != null && contentObj.value != ''" placement="top" effect="light">
            <el-tag plain size="medium" v-if="contentObj.value" :type="contentObj.type" class="truncate ..." :style="{ width: width }">
                <slot>{{ contentObj.value }}</slot>
            </el-tag>
        </el-tooltip>
    </div>
</template>
<script>
export default {
    props: {
        content: {
            type: Object,
        },

        width: {
            type: String,
            default: "112px",
        },
    },

    data() {
        return {
            contentObj: {},
        };
    },

    created() {
        this.contentObj = { ...this.content };
    },
};
</script>
<style scoped>
.el-tag {
    color: gray;
}
</style>
